<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="商会名称">
          <el-input v-model="title" placeholder="名称"></el-input>
        </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="search(1)">搜索</el-button>
      </el-form-item>
    </el-form>
   

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="search"
      ref="atb"
    >
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="addCoop(row)"
            >配置</el-button
          >
        </div>
      </template>
    </auto-table>
     <div class="flex count">
        <p class="">已配置合作商总数{{ rTotal }}</p>
    </div>

     <auto-table
      :DataList="rDataList"
      :option="rOption"
      :total="rTotal"
    >
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="delCoop(row)"
            >删除</el-button
          >
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>

export default {
  
  data() {
    return {
        DataList: [],
      Option: [
        { name: "名称", value: "title" },
        { name: "电话", value: "phone" },
        { name: "城市", value: "city"},
        { name: "操作", value: "handler",type:'custom' },
      ],
      Total: 0,
      rDataList: [],
      rOption: [
        { name: "名称", value: "cooperator.title" },
        { name: "电话", value: "cooperator.phone" },
        { name: "操作", value: "handler",type:'custom' },
      ],
      rTotal: 0,
      status: 2,
      id: "",
      tag: "",
      muti_ids: "",
      loading: false,
      title:'',
      mecid:''
    };
  },

  mounted() {
    this.id = this.$route.query.id;
    this.getList(1);
  },

  methods: {
    addCoop(row){
        this.$post("/user/cpCoopRelative/insert",{ package_id:this.id,coop_id:row.id}).then((res) => {
            this.$message("成功")
        this.getList(1)
        this.DataList = []
      });
    },
     search(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/cooperator/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status:0,
          title:this.title || null,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    addActivityCourse() {
      this.$refs.add.open({ activity_id: this.aid, activity_tag: this.tag });
    },
    //取消活动数据
    delCoop(row) {
      this.$confirm('确认解除绑定').then(() => {
        this.loading = true;
        this.$axios({
          url: "/user/cpCoopRelative/delete",
          params: {id:row.id},
    
        }).then((res) => {
        
          this.$message("取消成功");
         this.getList(1)
        });
      });
    },
 
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/cpCoopRelative/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,      
        package_id:this.id
        },
      }).then((res) => {
        this.rDataList = res.data.data.rows;
        this.rTotal = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.count {
  padding: 10px 30px;
  font-size: 16px;
}
</style>